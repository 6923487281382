<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-12 card p-1">
        <div>
          <div class="h2" style="color: #558cef">ผู้ขาย</div>
          <hr />
          <!-- search input -->
          <div class="custom-search row justify-content-between">
            <div class="col-12 col-md-3 mb-1">
              <b-button v-b-modal.modal-1>เพิ่มผู้ขาย</b-button>
            </div>

            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>
                    <!-- <b-dropdown-item v-b-modal.modal-Import>
                      <feather-icon icon="FileTextIcon" class="mr-50" />
                      <span>Import Excel</span>
                    </b-dropdown-item> -->
                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      Export Excel
                    </b-dropdown-item>
                    <!-- <b-dropdown-item>
                      <feather-icon icon="ClipboardIcon" class="mr-50" />
                      <span>Pdf</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>
          <b-modal id="modal-Import" title="Import Excel" ok-title="ตกลง" cancel-title="ยกเลิก" no-close-on-backdrop>
            <b-card-text>
              <h5>นำเข้าไฟล์ Excel</h5>
              <b-form-file type="file" @change="onFileChange" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
            </b-card-text>
          </b-modal>
          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              theme="my-theme"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'edit'" class="text-nowrap">
                  <b-button variant="outline-info" v-b-modal="`modal-edit${props.index}`">
                    <feather-icon icon="Edit3Icon" size="16" class="mx-1" />
                  </b-button>
                  <b-modal
                    :id="`modal-edit${props.index}`"
                    cancel-variant="outline-secondary"
                    ok-title="บันทึก"
                    cancel-title="ยกเลิก"
                    title="แก้ไขผู้ขาย"
                    size=""
                    @ok="edit_data(props.row)"
                    no-close-on-backdrop
                  >
                    <b-form>
                      <div>
                        <b-form-group label-cols="4" label-cols-lg="4" label="ประเภทการขาย:">
                          <template v-slot:label> ประเภทการขาย <span class="text-danger"> *</span> </template>
                          <v-select
                            id="basicInput"
                            v-model="props.row.vvv"
                            label="v_gname"
                            :options="option"
                            placeholder="ประเภทการขาย"
                            @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                          />
                        </b-form-group>
                        <b-form-group label-cols="4" label-cols-lg="4" label="ชื่อผู้ขาย:">
                          <template v-slot:label> ชื่อผู้ขาย <span class="text-danger"> *</span> </template>
                          <b-form-input
                            id="basicInput"
                            v-model="props.row.v_name"
                            placeholder="ชื่อผู้ขาย"
                            @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                          />
                        </b-form-group>
                        <b-form-group label-cols="4" label-cols-lg="4" label="ที่อยู่ผู้ขาย:">
                          <template v-slot:label> ที่อยู่ผู้ขาย <span class="text-danger"> *</span> </template>
                          <b-form-input
                            id="basicInput"
                            v-model="props.row.v_address"
                            placeholder="ที่อยู่ผู้ขาย"
                            @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                          />
                        </b-form-group>
                        <b-form-group label-cols="4" label-cols-lg="4" label="เบอร์โทรผู้ขาย:">
                          <template v-slot:label> เบอร์โทรผู้ขาย <span class="text-danger"> </span> </template>
                          <b-form-input
                            id="basicInput"
                            v-model="props.row.v_telephone"
                            placeholder="เบอร์โทรผู้ขาย"
                           
                            @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                          />
                        </b-form-group>
                        <b-form-group label-cols="4" label-cols-lg="4" label="แฟกซ์ผู้ขาย:">
                          <template v-slot:label> แฟกซ์ผู้ขาย <span class="text-danger"> </span> </template>
                          <b-form-input
                            id="basicInput"
                            v-model="props.row.v_fax"
                            placeholder="แฟกซ์ผู้ขาย"
                           
                            @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                          />
                        </b-form-group>
                        <b-form-group label-cols="4" label-cols-lg="4" label="อีเมล:">
                          <template v-slot:label> อีเมล <span class="text-danger"> </span> </template>
                          <b-form-input
                            id="basicInput"
                            v-model="props.row.v_email"
                            placeholder="อีเมล"
                            @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                          />
                        </b-form-group>
                        <b-form-group label-cols="4" label-cols-lg="4" label="หมายเลขภาษี:">
                          <template v-slot:label> หมายเลขภาษี <span class="text-danger"> </span> </template>
                          <b-form-input
                            id="basicInput"
                            v-model="props.row.v_taxnumber"
                            placeholder="หมายเลขภาษี"
                            @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"
                          />
                        </b-form-group>
                      </div>
                    </b-form>
                  </b-modal>
                </span>
                <span v-else-if="props.column.field === 'delete'" class="text-nowrap">
                  <b-button variant="outline-danger" @click="dateField(props.row)">
                    <feather-icon icon="Trash2Icon" size="16" class="mx-1" />
                  </b-button>
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => handlePageChange(value)"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- //model -->
      <div>
        <validation-observer ref="observer" v-slot="{ passes }">
          <b-modal
            id="modal-1"
            cancel-variant="outline-secondary"
            ok-title="บันทึก"
            cancel-title="ยกเลิก"
            title="ผู้ขาย"
            size="md"
            @ok.prevent="passes(getdata)"
            no-close-on-backdrop
          >
            <b-form>
              <div>
                <b-form-group label-cols="4" label-cols-lg="4" label="ประเภทการขาย:">
                  <template v-slot:label> ประเภทการขาย <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <v-select
                      label="v_gname"
                      :options="option"
                      id="basicInput"
                      v-model="v_gid"
                      :state="errors.length > 0 ? false : null"
                      placeholder="เลือกประเภทการขาย"
                    />
                    <div v-show="errors != ''">
                      <p class="text-danger">เลือกประเภทการขาย</p>
                    </div>
                  </validation-provider>
                </b-form-group>
                <b-form-group label-cols="4" label-cols-lg="4" label="ชื่อผู้ขาย:">
                  <template v-slot:label> ชื่อผู้ขาย <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-input id="basicInput" v-model="v_name" placeholder="ชื่อผู้ขาย" :state="errors.length > 0 ? false : null" type="text" />
                  </validation-provider>
                </b-form-group>
                <b-form-group label-cols="4" label-cols-lg="4" label="ที่อยู่ผู้ขาย:">
                  <template v-slot:label> ที่อยู่ผู้ขาย <span class="text-danger"> *</span> </template>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-input id="basicInput" v-model="v_address" placeholder="ที่อยู่ผู้ขาย" :state="errors.length > 0 ? false : null" />
                  </validation-provider>
                </b-form-group>
                <b-form-group label-cols="4" label-cols-lg="4" label="เบอร์โทรผู้ขาย:">
                  <template v-slot:label> เบอร์โทรผู้ขาย <span class="text-danger"> </span> </template>
                  <b-form-input
                    id="basicInput"
                    v-model="v_telephone"
                    placeholder="เบอร์โทรผู้ขาย"
                   
                  />
                </b-form-group>
                <b-form-group label-cols="4" label-cols-lg="4" label="แฟกซ์ผู้ขาย:">
                  <template v-slot:label> แฟกซ์ผู้ขาย <span class="text-danger"> </span> </template>
                  <b-form-input
                    id="basicInput"
                    v-model="v_fax"
                    placeholder="แฟกซ์ผู้ขาย"
                   
                  />
                </b-form-group>
                <b-form-group label-cols="4" label-cols-lg="4" label="อีเมล:">
                  <template v-slot:label> อีเมล <span class="text-danger"> </span> </template>
                  <b-form-input id="basicInput" v-model="v_email" placeholder="อีเมล" />
                </b-form-group>
                <b-form-group label-cols="4" label-cols-lg="4" label="หมายเลขภาษี:">
                  <template v-slot:label> หมายเลขภาษี <span class="text-danger"> </span> </template>
                  <b-form-input id="basicInput" v-model="v_taxnumber" placeholder="หมายเลขภาษี" />
                </b-form-group>
              </div>
            </b-form>
          </b-modal>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { BTable, BFormGroup, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { BButton } from 'bootstrap-vue';
import { BFormInput, BRow, BCol } from 'bootstrap-vue';
import { BFormFile } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import API from '@/views/connectDB/condb.js';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import readXlsxFile from 'read-excel-file';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import { async } from 'q';

export default {
  name: 'workgroup',
  components: {
    VueGoodTable,
    BFormFile,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormGroup,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    readXlsxFile,
    ValidationObserver,
    ValidationProvider,
    required,
  },

  data() {
    return {
      show: false,
      v_gid: '',
      v_name: '',
      v_address: '',
      v_telephone: '',
      v_fax: '',
      v_email: '',
      v_taxnumber: '',
      pageLength: 50,
      total: '',
      columns: [
        {
          label: 'ID',
          field: 'v_id',
        },
        {
          label: 'ชื่อผู้ขาย',
          field: 'v_name',
        },
        {
          label: 'ที่อยู่ผู้ขาย',
          field: 'v_address',
        },
        {
          label: 'เบอร์โทรผู้ขาย',
          field: 'v_telephone',
        },
        {
          label: 'แฟกซ์ผู้ขาย',
          field: 'v_fax',
        },
        {
          label: 'อีเมล',
          field: 'v_email',
        },
        {
          label: 'หมายเลขภาษี',
          field: 'v_taxnumber',
        },
        // {
        //   label: 'ชื่อย่อผู้ขาย',
        //   field: 'work_gname_short',
        // },
        {
          label: 'แก้ไข',
          field: 'edit',
          width: '5%',
        },
        {
          label: 'ลบ',
          field: 'delete',
          width: '5%',
        },
      ],
      rows: [],
      searchTerm: '',
      striped: false,
      bordered: true,
      borderless: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: true,
      footClone: true,
      headVariant: null,
      tableVariant: 'none',
      noCollapse: false,
      Excel: [],
      option: [],
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    this.venderType();
    this.show = true;
    const { access_token } = await this.access_token();
    const url = `${API}venderInfo?_page=1&_limit=100&_sort=-1`;
    const header = {
      headers: {
        Authorization: await access_token,
      },
    };
    const res = await axios.get(url, header);
    const hh = [];
    let ress = res.data.message.data.filter((el, index) => {
      return this.option.find((element) => {
        if (element.v_gid == el.v_gid) {
          // return {
          //   ...el,
          //   vvv: `${element.v_gname}`,
          // };
          hh[index] = {
            vvv: `${element.v_gname}`,
            ...el,
          };
        }
      });
    });
    console.log(hh);
    console.log(ress);
    this.rows = hh;
    this.total = res.data.message.total;
    this.show = false;
  },

  methods: {
    changeCell(vaule, column, row) {
      this.rows[row][column] = vaule;
      // console.log(this.rows[row][column]);
    },
    async venderType() {
      const { access_token } = await this.access_token();
      const url = `${API}vendertype`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      const data_res = res.data.message.data.map((dd) => {
        return {
          ...dd,
          v_gname: `${dd.v_gid} - ${dd.v_gname}`,
        };
      });
      this.option = data_res;
    },
    dateField: async function (props) {
      console.log(props);
      const { id, v_id, v_name } = props;

      Swal.fire({
        title: 'คุณต้องการจะลบข้อมูลใช่หรือไม่',
        text: `ผู้ขาย ${v_id} ${v_name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const { access_token } = await this.access_token();
          const url = `${API}venderInfo/${id}`;
          const head = {
            headers: {
              Authorization: await access_token,
            },
          };
          const res = await axios.delete(url, head);
          console.log(res.data.message);
          if (res.data.message === 'delete Complete') {
            Swal.fire({
              icon: 'success',
              title: 'ลบข้อมูลสำเร็จ!',
              text: `รายการนี้ ถูกลบเเล้ว `,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          } else {
            Swal.fire({
              icon: 'danger',
              title: 'ลบข้อมูลไม่สำเร็จ!',
              text: `${res.data.message}`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
          }
        }
        this.handlePageChange();
      });

      return;

      // console.log(res);
    },

    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        // console.log('rows:', rows);
        this.Excel = rows;
      });
    },
    async edit_data(value) {
      console.log(value);
      console.log(this.rows);
      const { access_token } = await this.access_token();
      const url = `${API}venderInfo/${value.id}`;
      const data = {
        v_gid: value.vvv.v_gid || value.v_gid,
        v_name: value.v_name,
        v_address: value.v_address,
        v_telephone: value.v_telephone,
        v_fax: value.v_fax,
        v_email: value.v_email,
        v_taxnumber: value.v_taxnumber,
      };
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };

      const res = await axios.put(url, data, head);
      console.log(res);
      this.handlePageChange();
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: 'ผู้ขาย',
        columns: [
          {
            title: 'ผู้ขาย',
            headerCellOptions: { textAlign: 'center' },
            children: [
              {
                title: 'รหัสผู้ขาย',
                field: 'unit',
              },
              {
                title: 'ชื่อผู้ขาย',
                field: 'name',
              },
            ],
          },
        ],
      });
    },
    async getdata() {
      let { access_token } = await this.access_token();
      let url = `${API}venderInfo`;
      let data = {
        v_gid: this.v_gid.v_gid,
        v_name: this.v_name,
        v_address: this.v_address,
        v_telephone: this.v_telephone,
        v_fax: this.v_fax,
        v_email: this.v_email,
        v_taxnumber: this.v_taxnumber,
      };
      let head = {
        headers: {
          Authorization: await access_token,
        },
      };
      if (this.rows === null && this.rows === undefined && this.rows === '' && this.rows === [] && this.rows === {}) {
        const res = await axios.post(url, data, head);
        // console.log(res.data.message);
        if (res.data.status === 200) {
          this.$bvModal.hide('modal-1');
          Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'บันทึกข้อมูล',
            showConfirmButton: false,
            timer: 1500,
          });
          this.handlePageChange();
        } else {
          Swal.fire({
            position: 'top-center',
            icon: 'error',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        this.v_name.replace(' ', '');
        const QC = this.rows.find(({ unit }) => unit === this.v_name);
        if (QC) {
          Swal.fire({
            position: 'top-center',
            icon: 'error',
            title: `ข้อมูลซ้ำ ${QC.unit} - ${QC.name}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          const res = await axios.post(url, data, head);
          // console.log(url);
          // console.log(data);
          // console.log(head);
          // console.log(res.data.message);
          if (res.data.status === 200) {
            this.$bvModal.hide('modal-1');
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'บันทึกข้อมูล',
              showConfirmButton: false,
              timer: 1500,
            });
            this.handlePageChange();
          } else if (res.data.message === 'already have this information') {
            Swal.fire({
              position: 'top-center',
              icon: 'error',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      }
    },
    async handlePageChange(currentPage) {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}venderInfo?_page=${currentPage}&_limit=${this.pageLength}&_sort=-1`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);

      this.rows = res.data.message.data;
      this.total = res.data.message.total;
      this.show = false;
    },

    async access_token() {
      //get access_token
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
